<template>
  <MainLayout>
    <!-- เนื้อหาหลักของ Layout จะมาอยู่ที่นี่ -->
    <router-view></router-view>
  </MainLayout>
</template>

<script>
import MainLayout from './components/MainLayout.vue';

export default {
  name: 'App',
  components: {
    MainLayout
  }
}

</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */

}

body {
  padding: 0%;
  margin: 0%;
  min-height: 100vh;
}


/* custom css */
.cards-text {
  margin: 0px;
  height: 30px;
}

.swiper {
  background-color: rgb(249, 249, 249) !important;
  padding: 10px;
  border-radius: 10px;
}

.swiper-pagination {
  display: none;
}

.swiper-slide {
  padding: 5px 5px;
  margin: 25px 0px;
  text-align: -webkit-center;
  text-align: -moz-center;
  margin-bottom: 40px;
}

.cards-brand {
  /* margin-bottom: 0.5rem; */
  margin-top: 1rem;
  padding: 10px;
}

.cards-brand img {
  max-width: 90px;
  height: auto;
  max-height: 60px;
}

.FloraBio,
.Filtrox,
.Amipolimer {
  max-height: 35px !important;
}

img.card-img {
  width: 100%;
  max-height: auto;
  object-fit: contain;
}

.ant-card-cover {
  display: flex;
  justify-content: center;
  min-height: 250px;
  max-height: 250px;
  padding: 1px 1px 0px 1px;
}

.ant-card-body {
  padding: 10px 10px 5px 10px !important;
  min-height: 100px;
}



@media only screen and (max-width: 1024px) {
  .ant-card-cover {
    min-height: 200px;
    max-height: 200px;
  }
}

@media only screen and (max-width: 768px) {

  .swiper-button-prev {
    left: 0px !important;
  }

  .swiper-button-next {
    right: 0px !important;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 25px;
  }

  .ant-card-cover {
    min-height: 200px;
    max-height: 200px;
  }

}

@media only screen and (max-width: 577px) {

  img.card-img {
    max-width: 250px;
  }

  .ant-card {
    /* max-width: fit-content; */
  }

}

@media only screen and (max-width: 400px) {
  .cards-container {
    max-width: fit-content;
  }

  .swiper-pagination {
    display: block;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    display: none;
  }

}
</style>
