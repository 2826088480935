<template>
    <section class="about-us pt-3">
        <div class="container">

            <div class="row">
                <div class="col-lg-12 text-center">
                    <h1 class="page-title">About us</h1>
                </div>
            </div>

            <div class="row mb-2">

                <div class="d-md-flex post-entry-2 half">
                    <a href="#" class="me-4 thumbnail text-center">
                        <img src="@/assets/images/LogoSPD.jpg" alt="SPD-logo" class="img-fluid brand-img"
                            style="object-fit: cover;">
                    </a>
                    <div class="text-start">
                        <div class="mt-2">About us</div>
                        <h2 class="mb-1 display-6 ">SPD Biotech Co.,Ltd.</h2>
                        <p>Science and Pharmaceutical equipments / Consumable products
                            Consultant services for Pharmaceutical and Biotechnology Solutions</p>
                    </div>
                </div>

            </div>

        </div>
    </section>

    <section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <h2 class="display-6">SPD Biotech Team</h2>
                </div>
            </div>

            <div class="row">
                <div :class="teamMember.id == 1 ? 'col-12 d-flex justify-content-center mb-2' : 'col-4 col-sm-4 col-lg-4    mb-2 text-center'"
                    v-for="(teamMember, index) in spd_bio_team" v-bind:key="index">
                    <div class="team-container" v-on:click="selectMember(teamMember.id)">
                        <a-avatar shape="circle" :size="{
                            xs: teamMember.id == 1 ? 85 : 60,
                            sm: teamMember.id == 1 ? 100 : 80,
                            md: teamMember.id == 1 ? 125 : 100,
                            lg: teamMember.id == 1 ? 125 : 100,
                            xl: teamMember.id == 1 ? 125 : 100,
                            xxl: teamMember.id == 1 ? 175 : 150
                        }" class="spd-avatar">
                            <template #icon>
                                <img :src="require(`@/assets/images/spd_team/${teamMember.img_filename}`)"
                                    :alt="teamMember.name" class="img-fluid"
                                    :style="{ 'object-fit': teamMember.id == 1 || teamMember.id == 3 || teamMember.id == 5  ? 'cover' : 'contain' }">
                            </template>
                        </a-avatar>
                        <h2 class="team-name">{{ teamMember.name }}</h2>
                        <span class="d-block mb-1 text-uppercase team-position">{{ teamMember.position }}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import data from '@/assets/data.js';

export default {
    name: 'AboutPage',
    data() {
        return {
            spd_bio_team: data.spd_biotech_team,
            // exp_list: data.spd_biotech_team.exp_list
        }
    },
    methods: {
        selectMember(Id) {
            this.$router.push({ name: 'employeeDetail', params: { Id } });
        }
    },

}
</script>

<style>
.spd-avatar {
    background: transparent;
    margin-bottom: 10px;
}

.ant-avatar>img {
    object-fit: contain;
}

.experience {
    text-align: start;
}



.post-meta {
    font-size: 11px;
    letter-spacing: 0.07rem;
    text-transform: uppercase;
    font-weight: 600;
    color: rgb(0, 0, 0, 0.4);
}

.brand-img {
    width: 100%;
    max-width: 300px;
}

.team-container:hover {
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;
    color: rgb(107, 121, 180);

}

.about-us {
    background-color: white;
}

@media (max-width: 768px) {
    .brand-img {
        width: 200px;
    }
}

@media (max-width: 600px) {
    .team-name {
        font-size: 20px;
    }

    .team-position {
        font-size: 12px;
    }
}

/* CSS สำหรับขนาดหน้าจอที่มีความกว้างน้อยกว่า 400px */
@media (max-width: 400px) {
    .brand-img {
        width: 150px;
    }

    .team-name {
        font-size: 16px;
    }

    .team-position {
        font-size: 10px;
    }
}
</style>
