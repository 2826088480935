<template>
  <div class="product-home mt-4">
    <h1>Product</h1>
    <a-row :gutter="16" class="mt-3 mb-3">
      <a-col :span="24" class="text-start pt-3 pb-3">
        <a-row type="flex">
          <a-col flex="auto">
            <a-select v-model:value="valueSelect" class="cate_selector" :options="productOptions" @focus="handleFocus"
              @blur="handleBlur" @change="selectCategory">
            </a-select>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="24">
        <swiper :slides-per-view="slidePerView" @swiper="onSwiper" @slideChange="onSlideChange" :modules="modules"
          navigation :pagination="{ clickable: true }">
          <swiper-slide v-for="(item, idx) in selectedCate" :key="idx">
            <div class="cards-container cards-hover" @click="selectProductModel(item.model_id, item.cate_id)">
              <a-card class="cards" hoverable>
                <template #cover>
                  <img :src="require('@/assets/images/products/' + item.model_image)
                    " class="card-img" alt="Product Image" loading="lazy" />
                </template>
                <p class="cards-text">{{ item.model_name }}</p>
                <div class="cards-brand">
                  <img :src="require('@/assets/images/brands/' + item.brand_image)" :alt="item.brand_name"
                    :class="item.brand_name.replace(/\s/g, '')" loading="lazy" />
                </div>
              </a-card>
            </div>
          </swiper-slide>
        </swiper>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import data from '@/assets/data.js';
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import 'swiper/css/scrollbar';
// import { ref, } from 'vue';

export default {
  props: [],
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    mapNestedArray(selectedCate) {
      return selectedCate.flatMap((category) => {
        return category.brands.flatMap((brand) => {
          return brand.products.flatMap((product) => {
            return product.models.map((model) => {
              return {
                product_name: product.product_name,
                brand_name: brand.name_brand,
                brand_image: brand.image_brand,
                model_id: model.id,
                model_name: model.name,
                model_image: model.img,
                cate_name: category.cate_name,
                cate_id: category.id,
              };
            });
          });
        });
      });
    },
    selectCategory(cate_id) {
      console.log(cate_id)
      let selectedCate = [];
      if (cate_id) {
        selectedCate = this.productCate.filter((el)=> el.id == cate_id)
        console.log("selectedCate",selectedCate)
        const ModelsByCate = this.mapNestedArray(selectedCate);
        this.selectedCate = ModelsByCate;
      } else {
        this.valueSelect = "All Categories";
        this.selectedCate = this.mapNestedArray(this.productCate);
      }
    },
    handleBlur() {
      // console.log("blur");
    },
    handleFocus() {
      // console.log("focus");
    },
    selectProductModel(Id, CateId) {
      // console.log("select Model", `model - ${Id}`, "cate - ", CateId);
      if (Id || CateId) {
        this.$router.push({ name: "product", params: { Id: Id, cate_id: CateId } });
      }
      window.scrollTo(0, 0);

    },
    prev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    next() {
      if (this.currentIndex < this.products.length - 4) {
        this.currentIndex++;
      }
    },
    editText(txt) {
      const splitText = txt.split("- ");
      var newText = ``;
      splitText.forEach((item) => {
        newText += item;
      });
      return newText;
    },
    handleResize() {
      const innerWidth = window.innerWidth;
      if (innerWidth < 400) {
        this.slidePerView = 1;
      } else if (innerWidth < 576) {
        this.slidePerView = 2;
      } else if (innerWidth <= 768) {
        this.slidePerView = 3;
      } else if (innerWidth <= 1199) {
        this.slidePerView = 4;
      } else {
        this.slidePerView = 5;
      }
    },
    arraysMatch(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;

      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }

      return true;
    },
  },
  data() {
    return {
      selectedCate: {},
      product_brands: [],
      currentIndex: 0,
      slidePerView: 1,
      valueSelect: "Select category",
      productAll: [],
      productCate: data.products_cate,
    };
  },
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log("start swiper", swiper);
    // };
    // const onSlideChange = () => {
    //   console.log("slide change");
    // };
    return {
      // onSwiper,
      // onSlideChange,
      modules: [Navigation, Pagination],
    };
  },
  mounted() {
    let Options = [
      {
        value: 0,
        label: "All",
      },
    ];

    this.productCate.forEach((category, index) => {
      Options.push({
        value: category.id,
        label: (index + 1) + ". " + category.cate_name,
      });
    });
    this.productOptions = Options;
    this.selectedCate = this.mapNestedArray(this.productCate);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.cards-brand{
  min-height: 80px;
}
.ant-collapse-item-active {
  width: 1500px;
  /* แก้ตามที่ต้องการ */
}

.see-more button a {
  text-decoration-line: underline;
}

.see-more {
  height: 350px;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

button a {
  text-decoration-line: none;
  color: #000;
}

.cate_selector {
  width: 100% !important;
  max-width: 420px;
}

:deep(.ant-card-bordered) {
  /* border: 0px; */
}

/* :deep(.swiper) {
  background-color: rgb(249, 249, 249) !important;
  padding: 10px;
} */

/* :deep(.swiper-slide) {
  padding: 5px 5px;
  margin: 25px 0px;
  text-align: -webkit-center;
  text-align: -moz-center;
} */

:deep(.swiper-pagination) {
  margin-top: 10px;
}

@media only screen and (max-width: 400px) {}

@media only screen and (max-width: 577px) {}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 1024px) {}
</style>
