<template>
    <div class="container">
        <h3 class="category-title">Product Category</h3>
        <div class="row">
            <div class="col-md-12">
                <div class="list" id="list">
                    <ul class="product-list" style="list-style: none;overflow: hidden;">
                        <li v-for="(item, index) in product_Cate" v-bind:key="index">
                            <div class="d-flex">
                                <div class="product-detail">
                                    <div class="date-product">
                                        <span class="">Product</span>
                                    </div>
                                    <span :to="{}" class="title-cate">
                                        <h3> {{ index + 1 + '.' }} {{ item.cate_name }}</h3>
                                    </span>
                                </div>
                            </div>
                            <swiper :slides-per-view="slidePerView" @swiper="onSwiper" @slideChange="onSlideChange"
                                :modules="modules" :pagination="{ clickable: true }" navigation>
                                <swiper-slide v-for="product, idx in item.models" v-bind:key="idx">
                                    <div class="cards-container" @click="selectProduct(product.model_id, item.cate_id)">
                                        <a-card class="cards" hoverable :bodyStyle={}>
                                            <template #cover>
                                                <img :src="require('@/assets/images/products/' + product.model_image)"
                                                    class="card-img" alt="Product Image" loading="lazy" />
                                            </template>
                                            <p class="cards-text">{{ product.model_name }}</p>
                                            <div class="cards-brand">
                                                <img :src="require('@/assets/images/brands/' + product.brand_image)"
                                                    :class="product.brand_image.replace(/\s/g, '')" loading="lazy" />
                                            </div>
                                        </a-card>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import data from '@/assets/data';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
    name: "ProductPage",
    props: [],
    data() {
        return {
            product_Cate: this.mapNestedArrayAllCate(data.products_cate),
            slidePerView: 0,
        }
    },
    setup() {

        // const onSwiper = (swiper) => {
        //     console.log("start swiper", swiper);
        // };
        // const onSlideChange = () => {
        //     console.log('slide change');
        // };

        return {
            // onSwiper,
            // onSlideChange,
            modules: [Navigation, Pagination],
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    methods: {
        mapNestedArrayAllCate(selectedCate) {
            const NewData = selectedCate.map((category) => {
                const models = category.brands.flatMap((brand) => {
                    return brand.products.flatMap((product) => {
                        return product.models.map((model) => {
                            return {
                                model_id: model.id,
                                model_name: model.name,
                                model_image: model.img,
                                brand_name: brand.name_brand,
                                brand_image: brand.image_brand,
                            };
                        });
                    });
                });

                return {
                    cate_id: category.id,
                    cate_name: category.cate_name,
                    models: models,
                };
            });
            return NewData;
        },
        selectProduct(Id, CateId) {
            this.$router.push({ name: "product", params: { Id:Id, cate_id:CateId } })
        },
        handleResize() {
            const innerWidth = window.innerWidth;
            if (innerWidth < 400) {
                this.slidePerView = 1;
            } else if (innerWidth < 440) {
                this.slidePerView = 2;
            } else if (innerWidth < 576) {
                this.slidePerView = 2;
            } else if (innerWidth <= 768) {
                this.slidePerView = 3;
            } else if (innerWidth <= 1199) {
                this.slidePerView = 4;
            } else {
                this.slidePerView = 4;
            }
        }
    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

}
</script>

<style scoped>
.cards {
    /* background-color: transparent; */
}

.cards-brand img {
    max-width: 50%;
    height: auto;
    max-height: 60px;
}

.product-name {
    padding-top: 10px;
}

.title-cate {
    color: #000;
    text-decoration-line: none;
}

.sub_cate li {
    padding: 0px !important;
    list-style-type: none;
}

.product-list li {
    padding: 15px 0px;
}

.category-title,
.product-detail {
    text-align: start;
}





@media only screen and (max-width: 768px) {
    .cards-container {
        max-width: max-content;
    }

}

@media only screen and (max-width: 577px) {}

@media only screen and (max-width: 400px) {}
</style>

<style scoped>
:deep(.swiper-horizontal) {
    background-color: rgb(249, 249, 249) !important;
}

:deep(.swiper-slide) {
    padding: 5px 5px;
    margin: 25px 0px;

}

@media only screen and (max-width: 768px) {
    /* :deep(.ant-card-cover) {
        min-height: 150px;
        max-height: 150px;
    } */

    /* :deep(.ant-card-body) {
        min-height: 100px;
    } */

    :deep(.swiper-button-prev) {
        left: 0px !important;
    }

    :deep(.swiper-button-next) {
        right: 0px !important;
    }
}

@media only screen and (max-width: 400px) {
    :deep(.swiper-slide) {
        text-align: -webkit-center;
        text-align: -moz-center;
    }

    /* :deep(.ant-card-cover) {
        min-height: 100px;
        max-height: 100px;
    } */
}
</style>