import App from "./App.vue";
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import Antd from "ant-design-vue";
import "bootstrap/dist/css/bootstrap.css";

// Components
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";
import Product from "@/views/Product.vue";
import EmployeeDetail from "@/views/EmployeeDetail.vue"
import NotFound from "@/views/NotFound.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: Home },
    { path: "/about", component: About },
    { path: "/contact", component: Contact },
    { path: "/product/:Id/:cate_id", name: "product", component: Product },
    { path: "/employee/:Id", name: "employeeDetail", component: EmployeeDetail },
    // { path: '/*', component: NotFound },
    { path: '/:pathMatch(.*)*', component: NotFound },
  ],
});
const app = createApp(App);
app.use(router);
app.use(Antd);
app.mount("#app");
