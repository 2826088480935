<template>
    <div class="container pt-3">
        <a-breadcrumb v-if="this.mode == 'all'">
            <a-breadcrumb-item>All</a-breadcrumb-item>
        </a-breadcrumb>

        <a-breadcrumb v-else>
            <!-- <a-breadcrumb-item>Home</a-breadcrumb-item> -->
            <a-breadcrumb-item v-if="cateSelected.name || productSelected.name"> <router-link
                    :to="{ name: 'product', params: { Id: 'null', cateId: 'all' } }">Product</router-link></a-breadcrumb-item>
            <a-breadcrumb-item v-if="cateSelected.cate_name">{{ cateSelected.cate_name.replace("/", "")
            }}</a-breadcrumb-item>
            <a-breadcrumb-item v-if="productSelected.product_name">{{ productSelected.product_name.replace("/", "")
            }}</a-breadcrumb-item>
            <a-breadcrumb-item v-if="productSelected.model_name">{{ productSelected.model_name.replace("/", "")
            }}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>
    <section id="product-cate-list" class="mt-3">
        <ProductCate v-if="mode == 'all'" />
        <ProductDetail v-else />
    </section>
</template>

<script>
import ProductCate from '@/components/ProductCate.vue';
import ProductDetail from '@/components/ProductDetail.vue';
import data from '@/assets/data';

export default {
    name: "ProductPage",
    components: {
        ProductCate,
        ProductDetail
    },
    data() {
        return {
            mode: "all",
            products_Cate: data.products_cate,
            cateSelected: '',
            productSelected: '',
        }
    },
    created() {
        const { Id, cate_id } = this.$route.params;
        if (Id && cate_id && Id != 0) {
            // console.log("id", Id, cate_id)
            this.findProductByIdAndCategory(Id, cate_id);
            this.mode = "id";
        } else {
            // console.log("all")
            this.mode = "all";
        }
    },
    mounted() {
        // กำหนดตำแหน่ง scroll เมื่อหน้าโหลดเสร็จ
        this.$nextTick(() => {
            window.scrollTo(0, 0);
        });
    },
    methods: {
        findProductByIdAndCategory(Id, category) {
            const selectedCate = this.products_Cate.find((el) => el.id == category);
            const models = selectedCate.brands.flatMap((brand) => {
                return brand.products.flatMap((product) => {
                    return product.models.map((model) => {
                        if (model.id == Id) {
                            return {
                                product_name: product.product_name,
                                brand_name: brand.name_brand,
                                brand_image: brand.image_brand,
                                model_id: model.id,
                                model_name: model.name,
                                model_desc: model.desc,
                                model_image: model.img,
                                model_images: model.images ? model.images : [],
                            };
                        }
                    });
                });
            });

            const filteredModel = models.filter((element) => element !== undefined && element !== null);
            this.cateSelected = selectedCate;
            this.productSelected = filteredModel[0];
        },
    },
    watch: {
        $route(newValue) {
            const productId = newValue.params.Id;
            const cateId = newValue.params.cate_id;
            const allMode = cateId == "all" || productId == 0 || cateId == undefined || productId == undefined;

            if (allMode == false) {
                this.mode = "id";
                this.findProductByIdAndCategory(productId, cateId);
                // console.log(newValue.params, oldValue)
            } else {
                this.mode = "all";
            }
        },
    }

}
</script>


<style></style>