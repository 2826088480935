<template>
    <div class="product-detail-header" style="padding: 10px;">
        <h1>Product Detail</h1>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 col-12">
                <a-image v-if="product.model_image" :preview="{ visible: false }" class="image-product"
                    :src="require('@/assets/images/products/' + product.model_image)" @click="visible = true" />
                <div v-if="product.model_image" class="d-flex justify-content-center">
                    <div v-for="(image, index) in product.images_file" :key="index" style="padding: 10px;">
                        <a-image :src="require('@/assets/images/products/' + image)"
                            :class="{ selected: index === selectedImageIndex }" style="width: 100%;max-width: 100px;" />
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-12 products-col" v-if="product.model_name || product.model_desc">
                <h3>{{ product.model_name }}</h3>
                <a-divider orientation="left" class="product-devider">{{ product.product_name.toUpperCase() }}</a-divider>
                <!-- {{ product.model_desc }} -->
                <div v-html="product.model_desc"></div>
                <!-- <span>{{ product.desc.title }}</span> -->
                <!-- <ul>
                    <li v-for="desc, index in product.desc.list" v-bind:key="index">{{ desc }}</li>
                </ul> -->
                <div>
                    <b>Brand : {{ product.brand_name }}</b> <br>
                    <img :src="require('@/assets/images/brands/' + product.brand_image)" class="image-brand" width="170"
                        loading="lazy" />
                </div>
            </div>
        </div>

        <div style="display: none">
            <a-image-preview-group :preview="{ visible, onVisibleChange: vis => (visible = vis) }">
                <div v-if="product.model_image">
                    <a-image :src="require(`@/assets/images/products/` + product.model_image)" />
                </div>

                <!-- <div v-if="product.images_file">
                    <a-image v-for="image, index in product.images_file" :src="require(`@/assets/images/products/` + image)"
                        v-bind:key="index" />
                </div> -->
            </a-image-preview-group>
        </div>

        <div class="row mt-4 mb-3">
            <a-divider>Related Product</a-divider>
            <div class="d-flex justify-content-end p-0">
                        <router-link :to="{ name: 'product', params: { Id: '0', cateId: 'all' } }">
                            <button class="btn btn-secondary">All Categories</button>
                        </router-link>
                </div>
            <a-empty class="pt-5 pb-5" v-if="relatedProduct == ''">
                <template #description>ไม่มีรายการที่เกี่ยวข้อง</template>
            </a-empty>
            <div class="product-related" v-else>
               
                <swiper :slides-per-view="slidePerView" @swiper="onSwiper" @slideChange="onSlideChange" :modules="modules"
                    navigation :pagination="{ clickable: true }">
                    <swiper-slide v-for="(item, idx) in relatedProduct" :key="idx">
                        <div class="cards-container cards-hover" @click="selectModel(item.model_id, selectedCate.id)">
                            <a-card class="cards" hoverable>
                                <template #cover>
                                    <img :src="require('@/assets/images/products/' + item.model_image)" class="card-img"
                                        alt="Product Image" loading="lazy" style="width: 100%;height: auto;" />
                                </template>
                                <h6>{{ item.model_name }}</h6>
                                <p class="cards-text">{{ item.brand_name }}</p>
                                <div class="cards-brand">
                                    <img :src="require('@/assets/images/brands/' + item.brand_image)"
                                        :class="item.brand_name.replace(/\s/g, '')" class="card-brand-img" loading="lazy" />
                                </div>
                            </a-card>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import data from '@/assets/data';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { onMounted, onBeforeUnmount, ref } from 'vue';
// import { useRouter } from 'vue-router';
const visible = ref(false);
export default {
    props: [],
    components: {
        Swiper,
        SwiperSlide,
    },
    methods: {
        mapNestedArray(selectedCate) {
            const models = selectedCate.brands.flatMap((brand) => {
                return brand.products.flatMap((product) => {
                    return product.models.map((model) => {
                        return {
                            model_id: model.id,
                            model_name: model.name,
                            model_image: model.img,
                            model_desc: model.desc,
                            brand_name: brand.name_brand,
                            brand_image: brand.image_brand,
                            product_name: product.product_name,
                        };
                    });
                });
            });

            return {
                cate_id: selectedCate.id,
                cate_name: selectedCate.cate_name,
                models: models,
            };
        },
        updateProductDetail(Id, cate_id) {

            const selectedCate = this.products_Cate.find((el) => el.id == cate_id);
            const models = selectedCate.brands.flatMap((brand) => {
                return brand.products.flatMap((product) => {
                    return product.models.map((model) => {
                        if (model.id == Id) {
                            return {
                                product_name: product.product_name,
                                brand_name: brand.name_brand,
                                brand_image: brand.image_brand,
                                model_id: model.id,
                                model_name: model.name,
                                model_desc: model.desc,
                                model_image: model.img,
                                model_images: model.images ? model.images : [],
                            };
                        }
                    });
                });
            });

            const findIndexModel = this.mapNestedArray(selectedCate).models.findIndex((el) => el.model_id == Id);
            const selectedModel = models[findIndexModel]
            this.product = selectedModel;
            this.selectedCate = selectedCate;
            this.relatedProduct = this.mapNestedArray(selectedCate).models.filter(el => el.model_id !== Id);

            if (document.getElementsByClassName("ant-breadcrumb-link")[2]) {
                document.getElementsByClassName("ant-breadcrumb-link")[2].innerText = selectedModel.model_name.replace("/", "");
            }

            window.scrollTo(0, 0);


        },
        selectModel(itemId, cateId) {
            this.updateProductDetail(itemId, cateId)
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        }

    },
    data() {
        return {
            visible: visible,
            products_Cate: data.products_cate,
            product: {},
            relatedProduct: {},
            selectedCate: {},
            // selectedId: null,
            selectedImageIndex: '',
        }
    },
    setup() {
        const windowWidth = ref(window.innerWidth);
        const slidePerView = ref(4);

        const handleResize = () => {
            const innerWidth = window.innerWidth;
            windowWidth.value = window.innerWidth;

            if (innerWidth < 400) {
                slidePerView.value = 1;
            } else if (innerWidth < 576) {
                slidePerView.value = 2;
            } else if (innerWidth <= 768) {
                slidePerView.value = 3;
            } else if (innerWidth <= 1199) {
                slidePerView.value = 4;
            } else {
                slidePerView.value = 5;
            }
        }

        onMounted(() => {
            handleResize();
            window.addEventListener('resize', handleResize);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', handleResize);
        });

        return {
            modules: [Navigation, Pagination],
            windowWidth,
            slidePerView
        };
    },
    created() {
        const { Id, cate_id } = this.$route.params;
        this.updateProductDetail(Id, cate_id)

    },
    watch: {

    }

}
</script>

<style scoped>
.products-col {
    text-align: start;
}

.image-brand {
    padding: 10px;
}

:deep(.ant-image) {
    max-width: 400px;
}


@media only screen and (max-width: 400px) {
    .product-devider{
        white-space: unset;
    }
}

@media only screen and (max-width: 576px) {}

@media only screen and (max-width: 768px) {
    .image-brand {
        max-width: 100px;
    }

    .image-product {
        max-width: 250px;
    }

    .products-col {
        padding: 10px 40px;
    }
}
</style>


