<template>
  <a-layout>
    <HeaderComponent />


    <a-layout-content :style="contentStyle">
      <slot></slot>
    </a-layout-content>

    <FooterComponent />
    <a-back-top />

  </a-layout>
</template>
  
<script>
import { onMounted } from 'vue';
import HeaderComponent from '@/components/Header.vue';
import FooterComponent from '@/components/Footer.vue';
export default {
  name: 'MainLayout',
  components: {
    HeaderComponent,
    FooterComponent,
  },
  data() {
    return {
      contentStyle: {
        // minHeight: "100vh",
      },

    }
  },
  setup() {
    onMounted(() => {
      // เพิ่มการตรวจสอบว่ามีการสไลด์หรือไม่
      checkScroll();
    });

    const checkScroll = () => {
      // เมื่อมีการสไลด์หน้าจอให้แสดงปุ่ม
      if (window.pageYOffset > 10) {
        document.documentElement.scrollTop = 0;
        // document.body.scrollTop = 0;
      }
    };

    return {
      checkScroll
    }

  },
  watch: {
    $route() {
      // เมื่อ URL เปลี่ยน
      this.checkScroll();
      // document.documentElement.scrollTop = 0;
      // document.body.scrollTop = 0;
    }
  }

}
</script>

  
<style scoped>
@font-face {
  font-family: 'EBGaramond';
  src: url('@/assets/fonts/EB_Garamond/static/EBGaramond-Regular.ttf') format('truetype');
}

* {
  font-family: 'serif';
}

/* สไตล์ที่เฉพาะกับ Component นี้ */
.ant-layout .ant-layout-content {
  background-color: white;
}
</style>

  