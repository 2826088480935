<template>
  <section id="contact" class="contact mt-5 mb-5">
    <div class="container aos-init aos-animate" data-aos="fade-up">

      <div class="row">
        <div class="col-lg-12 text-center mb-5">
          <h1 class="page-title">Contact us</h1>
        </div>
      </div>
      <br>
      <div class="row gy-4">

        <div class="col-md-4">
          <div class="info-item">
            <i class="bi bi-geo-alt"></i>
            <h3>Address</h3>
            <address>
              1194 Soi Wachirathamsathit 57
              Sukhumvit 101/1
              Bangchak, Phrakanong
              Bangkok 10260&nbsp;
            </address>
          </div>
        </div><!-- End Info Item -->

        <div class="col-md-4">
          <div class="info-item info-item-borders">
            <i class="bi bi-phone"></i>
            <h3>Phone Number</h3>
            <a href="tel:+66 21854333">+66 21854333 ต่อ 2202</a><br>
            <a href="tel:+66 986017542">+66 986017542</a><br>
            <a href="tel:+66 955594658">+66 955594658</a><br>
          </div>
        </div><!-- End Info Item -->

        <div class="col-md-4">
          <div class="info-item">
            <i class="bi bi-envelope"></i>
            <h3>Email</h3>
            <a href="mailto:sales@spdbiotech.com">sales@spdbiotech.com</a><br>
            <a href="mailto:support@spdbiotech.com">support@spdbiotech.com</a><br>
            <a href="mailto:ps@spdbiotech.com">ps@spdbiotech.com</a><br>
          </div>
        </div>
        <br>
        <div class="col-lg-12 text-center mb-5">
          <a-spin :indicator="indicator" v-if="loading" id="loading" />
          <div class="map-container">
            <iframe id="map-iframe" @load="handleIframeLoad"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.289735110103!2d100.631408!3d13.700893899999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d6181b7cc6d6b%3A0x4554b8cd48a7134b!2z4Lia4Lij4Li04Lip4Lix4LiXIOC5gOC4reC4quC4nuC4teC4lOC4tSDguYTguJrguYLguK3guYDguJfguIQg4LiI4Liz4LiB4Lix4LiU!5e0!3m2!1sth!2sth!4v1690205025221!5m2!1sth!2sth"
              width="800" height="400" style="border:0;" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>

      </div>


    </div>
  </section>
</template>

<script>
import { LoadingOutlined } from '@ant-design/icons-vue';
import { h } from 'vue';
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '24px',
  },
  spin: true,
});
export default {
  name: "ContactPage",
  methods: {
    handleIframeLoad() {
      this.loading = false;
    }
  },
  data() {
    return {
      loading: true,
      indicator
    }
  },
  watch: {
    loading(newValue, oldValue) {
      console.log(newValue, oldValue)
    }
  }

}
</script>

<style>
@media only screen and (max-width: 991px) {
  .map-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-top: 75%;
  }

  .map-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
</style>