<template>
  <a-layout-header :style="headerStyle">
    <nav class="navbar-style">

      <div class="navbar-brand-logo">
        <router-link to="/" class="logo logo-brand">
          <img src="@/assets/images/LogoSPD-no-bg.png" loading="lazy">
          <span>SPD Biotech</span>
        </router-link>
        <span class="toggle-icon" id="toggle-icon" @click="toggleActiveMenu">&#9776;</span>
      </div>

      <ul class="navbar-menu" v-bind:class="{ active: toggleMenu }" id="navbar-menu">
        <li :class="{ 'active': activeMenu === 1 }" @click="toggleActiveMenu">
          <a-divider type="vertical" style="padding-right: 10px;" />
          <router-link to="/" @click="activeMenu = 1">Home</router-link>
        </li>
        <li :class="{ 'active': activeMenu === 2 }" @click="toggleActiveMenu">
          <a-divider type="vertical" style="padding-right: 10px;" />
          <router-link to="/about" @click="activeMenu = 2">About</router-link>
        </li>
        <li :class="{ 'active': activeMenu === 3 }" @click="toggleActiveMenu">
          <a-divider type="vertical" style="padding-right: 10px;" />
          <router-link :to="{ name: 'product', params: { Id: 0, cate_id: 'all' } }"
            @click="activeMenu = 3">Product</router-link>
        </li>
        <li :class="{ 'active': activeMenu === 4 }" @click="toggleActiveMenu">
          <a-divider type="vertical" style="padding-right: 10px;" />
          <router-link to="/contact" @click="activeMenu = 4">Contact</router-link>
        </li>
      </ul>

    </nav>
  </a-layout-header>
</template>

<script>
// import { onBeforeRouteUpdate } from 'vue-router';
import { onMounted } from 'vue';
export default {
  name: 'HeaderPage',
  data() {
    return {
      activeMenu: 1,
      toggleMenu: false,
      headerStyle: {
        // background: "transparent",
        background: "#71789f1a",
      }
    }
  },
  setup() {
    onMounted(() => {
      // console.log("moundted header")
      // document.documentElement.scrollTop = 0;
      // window.scrollTo({
      //       top: 0,
      //       behavior: 'smooth'
      //   });
      // window.scrollTo(0, 0);
    });
  },
  methods: {
    toggleActiveMenu() {
      this.toggleMenu = !this.toggleMenu;
    },
    checkActiveMenu(path) {
      const activePath = path.split("/")[1];
      switch (activePath) {
        case 'about':
          this.activeMenu = 2;
          break;
        case 'employee':
          this.activeMenu = 2;
          break;
        case 'product':
          this.activeMenu = 3;
          break;
        case 'contact':
          this.activeMenu = 4;
          break;
        default:
          this.activeMenu = 1;
          break
      }
    },

  },
  watch: {
    $route(to) {
      // เมื่อ URL เปลี่ยน
      // console.log("watch change route on header")
      // document.documentElement.scrollTop = 0;
      // document.body.scrollTop = 0;
      this.checkActiveMenu(to.fullPath);
    }
  }
}
</script>

<style>
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.navbar-style {
  /* background: #f2f2f2; */
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  /* padding: 10px 20px; */

  max-height: 65px;
}

.navbar-brand-logo {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0 10px;

  & span {
    white-space: nowrap;
  }
}

.logo-brand img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.logo-brand span {
  font-weight: 700;
  color: #000000 !important;
  /* font-size: 24px !important; */
  font-size: 20px !important;
  padding-left: 5px;
}

.logo {
  font-weight: bold;
  font-size: 1.2em;
  display: contents;
}

.logo h1 {
  margin: 0 15px;
}

.navbar-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: end;
  flex-grow: 1;
  z-index: 100 !important;

}

.navbar-menu li {
  margin: 0 10px;
}

.navbar-menu li a {
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: #000000;
}

.navbar-menu li.active a {
  /* background-color: #3835de; */
  text-decoration: underline;
  text-decoration-line: 10;
  color: #000000;
}

.toggle-icon {
  font-size: 1.5em;
  cursor: pointer;
  display: none;
  margin-left: 15px;
}

@media (max-width: 768px) {
  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    background-color: #f2f2f2;
    width: 100%;
    left: 0;
    top: 64px;
    z-index: 10;
  }

  .navbar-menu.active {
    display: flex;
  }

  .navbar-menu li {
    margin: 10px 0;
  }

  .toggle-icon {
    display: block;
  }
}
</style>