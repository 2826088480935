<template>
  <div class="main-container container pt-4">
    <div class="row">
      <div class="column-pic col-sm-4 col-md-4 col-lg-6">
        <img :src="imgUrl" />
      </div>

      <div class="column-text col-sm-8 col-md-8 col-lg-6">
        <h3>{{ selectedMember.name }}</h3>
        <hr>
        <ul class="experience">
          <li v-for="(exp, idx) in selectedMember.exp_list" :key="idx">
            {{ exp.text }}
            <ul v-if="exp.sub">
              <li v-for="subExp in exp.sub" :key="subExp">{{ subExp }}</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    
  </div>
</template>

<script>
import data from "@/assets/data.js";

export default {
  props: {
    // employee: {
    //   type: Object,
    //   required: true
    // }
  },
  data() {
    return {
      spd_bio_team: data.spd_biotech_team,
      selectedMember: null,
      idFromParams: this.$route.params.Id,
      imgUrl: null,
    };
  },
  methods: {
    getMemberById(id) {
      return this.spd_bio_team.find((member) => member.id === parseInt(id));
    },
  },
  created() {
    console.log(this.idFromParams);
    console.log(this.spd_bio_team);

    const selectMem = this.getMemberById(this.idFromParams);
    this.selectedMember = selectMem;

    import(`@/assets/images/spd_team/${selectMem.img_filename}`)
      .then((img) => {
        this.imgUrl = img.default;
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style scoped>
.column-text {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  text-align: start;
}

.main-container {
  display: flex;
  justify-content: center;
  /* min-height: 100vh !important; */
  min-height: calc(100vh - 100px);
}

.column-pic {
  /* display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center; */
  padding-top: 15px;
  max-width: 200px;
}

.employee-details {
  text-align: start;
}

img {
  width: 100%;
  height: auto;
  max-width: 250px;
  border-radius: 5px;
}

/* footer {
  position: fixed;
  bottom: 100px;
  width: 100%;
} */
/* .footer-legal {
  position: fixed;
  bottom: -50;
  width: 100%;
} */
@media (max-width: 576px) {
  img {
    max-width: 150px;
  }
}

@media (max-width: 768px) {
  .column-text {
    text-align: center;
  }

  /* img {
    max-width: 150px;
    max-height: 150px;
  } */
}
</style>

<style>
.ant-layout-content {
  background-color: rgb(249, 249, 249) !important;
}
</style>
