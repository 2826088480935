const data = {
  spd_biotech_team: [
    {
      id: 1,
      name: "Duangta Urasilp",
      position: "MANAGING DIRECTOR",
      img_filename: "MD.png",
      exp_list: [
        {
          text: "Experience more than 20 years in Pharmaceutical market.",
        },
        {
          text: " The products portfolio includes:",
          sub: [
            "Bioreactor",
            "Cross Flow filtration/Membrane Filter system",
            "Upstream & Downstream process",
            "Freeze Dryer",
            "Filter Press System",
            "Filling line system",
            "Isolator",
            "Belimed Steam Steriliser",
            "GMP Washer, etc.",
          ],
        },
        {
          text: "Manage Key account relationships for long term growth",
        },
        {
          text: "Has a wide network of Key Opinion Leaders in Thailand",
        },
      ],
    },
    {
      id: 2,
      name: "Sakaorat Teeravitthayaart",
      position: "Application Manager",
      img_filename: "Sakaorat.jpg",
      exp_list: [
        {
          text: "Experience more than 28 years in analytical instrumentation used in the analysis of industries including quality control laboratory, food&beverage ,water conditioning and waste water.",
        },
        {
          text: " The products portfolio includes:",
          sub: [
           `Ph ,Conductivity and Dissolved Oxygen Meters`,
           `Automatic Titrators ,Karlfisher Titrators`,
           `Spectrophotometer`,
          ],
        },
        {
           text: "Experience with products turnover of THB ~100,000,000.",
        },
      ],
    },
    {
      id: 3,
      name: "Kwanchai Arunpoo",
      position: "ASSISTANT AFTER SALES SERVICE MANAGER",
      img_filename: "assSM.png",
      exp_list: [
        {
          text: "Experience more than 10 years in Pharmaceutical market for Installation & Commissioning of products",
          sub: [
            "Belimed Steam Steriliser (2000 lits, 800 lits, etc.)",
            "Bioreactor (50 lits, 10 lits, 5 lits, 3 lits)",
            "Cross Flow filtration / Membrane Filter system",
            "Plasma Steriliser",
            "Calibration for Integrity tester",
            "Installation for Inspection machine",
          ],
        },
      ],
    },
    {
      id: 4,
      name: "Neungruthai Thamteang",
      position: "SENIOR PRODUCT SPECIALIST",
      img_filename: "SP.png",
      exp_list: [
        {
          text: "Experience more than 6 years in Pharmaceutical market, Hospital (Disinfection control and CSSD).",
        },
        {
          text: " The products portfolio includes:",
          sub: [
            "Belimed Steam Steriliser",
            "GMP Washer",
            "Plasma Sterilisation",
            "Disinfection Device & disinfection solution",
          ],
        },
        {
          text: "Experience in product market assessment, installation, and supporting sales force for 10 sales representatives.",
        },

      ],
    },
    {
      id: 5,
      name: "Wilasinee Saeneab",
      position: "Senior Sales Representative",
      img_filename: "Wilasonee.jpg",
      exp_list: [
        {
          text: "Experience more than 15 years in Pharmaceutical market."
        },
        {
          text: "The products portfolio includes:",
          sub: [
            "Bioreactor",
            "Cross Flow filtration / Membranes Filter system",
            "Upstream & Downstream process",
          ],
        }
      ]
    },
  ],
  products_cate: [
    { // Cate 1
      id: "7b8ecf9290b8417f8d565e60508871bb",
      cate_name: "Cell Culture media",
      cate_image: "Cell-Culture-media.png",
      brands: [
        {
          id: "ec3713f9b7594e9ba4c82ab430519596",
          name_brand: "FloraBio",
          image_brand: "Flora bio Logo.jpg",
          products: [
            {
              id: "8534a0795a7f4862b501858d6a6987ec",
              product_name: "Culture medium",
              models: [
                {
                  id: "9a133fb13108420fa754c71b51dd6793",
                  name: "Culture medium",
                  img: "1.1 Culture medium.jpg",
                  desc: `<ul>
                      <li> Culture media are produced under GMP conditions as well as EMA and FDA regulations.</li>
                      <li> The media are available in liquid or in powder formulation.</li>
                      <li> The culture media are completely synthetic.</li>
                      <dl>
                        <dt>They are free of:</dt>
                        <dd>
                          <ul>
                            <li>Serum</li>
                            <li>Animal components</li>
                            <li>Hydrolysate</li>
                            <li>Growth factor</li>
                          </ul>  
                        <dd>
                      </dl>
                    </ul>`
                }
              ]
            }
          ]
        },
      ]
    },
    { // Cate 2
      id: "117966ebf1e04df594fcc309a8b0073e",
      cate_name: "Dispensing Powder pump / Peristaltic pump",
      cate_image: "Dispensing Powder-pump-Peristaltic-pump.png", // pleae make new image cate
      brands: [
        {
          id: "44f42335fc2d4856ac66e8d023d0e8c1",
          name_brand: "Next Company",
          image_brand: "NEXT_PUMP Logo.png",
          products: [
            {
              id: "3732f12bce024100a0caa69f2430c86c",
              product_name: "Transfer/Dispensing powder pump",
              models: [
                {
                  id: "ebe8c368063d4bc0b40b1a4694f11281",
                  name: "DC PUMP",
                  img: "DC PUMP - Next company.jpg",
                  desc: `<ul>
                    <li>Model: NEXT DC PUMP</li>
                    <li>Speed: MAX.</li>
                    <li>Type: Analog</li>
                    <li>Flow rate: 0-617 gram/min (base on white sugar) * Flow rate may vary depending on the experimental environment.</li>
                    <li>Power: DC 24V / ≤ 36W</li>
                  </ul>`
                },
                {
                  id: "72ee7a35aa5c414a9706e4a77b9c0361",
                  name: "300FJ50 PMP",
                  img: "300fj50 pmp - Next company.png",
                  desc: `<ul>
                    <li>Model: NEXT 300FJ50 PMP</li>
                    <li>Speed: 0.1- 300 RPM (Speed resolution : 0.1 rpm)</li>
                    <li>Type: LED Type ( 4-digit)</li>
                    <li>Suction speed: 10 - 300 rpm</li>
                    <li>Suction angle: 10 - 720˚ (1˚ stepping) Work environment: Temperature 0-40 ˚, relative humidity, no condensation</li>
                    <li>Numbers of dispensing: 0 - 9999 T</li>
                    <li>Power: AC 200V ± 10% (Standard)</li>
                    <li>Dispensing time: 0.1s - 9999 min</li>
                    <li>External control: RS232 and RS485, support MODBUS protocol mode</li>
                    <li>Stop time: 0.1s - 9999 min</li>
                  </ul>`
                },
                {
                  id: "45e31e682460456598759bd5a5e2a86b",
                  name: "600-4F PMP",
                  img: "600-4f - Next company.png",
                  images:[
                    "600-4f.1 - Next company.png",
                  ],
                  desc: `<ul>
                    <li>Model: NEXT 600-4F PMP</li>
                    <li>Speed: 0.1- 600 RPM (Speed resolution : 0.1 rpm)</li>
                    <li>Type: LED Type ( 4-digit)</li>
                    <li>Suction speed: 10 - 300 rpm</li>
                    <li>Suction angle: 10 - 720˚ (1˚ stepping)</li>
                    <li>Work environment: Temperature 0-40 ˚, relative humidity, no condensation</li>
                    <li>Numbers of dispensing: 0 - 9999 T</li>
                    <li>Power: AC 200V ± 10% (Standard)</li>
                    <li>Dispensing time: 0.1s - 9999 min</li>
                    <li>External control: RS232 and RS485, support MODBUS protocol mode</li>
                    <li>Stop time: 0.1s - 9999 min</li>
                    <li>Flow rate: 0- 165 gram/min at 100rpm (base on white sugar) *flowrate may vary depending on the experimental environment.</li>
                  </ul>`
                },
              ]
            },
            {
              id: "d457d4b8ed194deb9c62df7abf037eac",
              product_name: "Powder Liquid Select Pump System",
              models: [
                {
                  id: "d8e1cf21b1d74e618b4b0dad3d9f8fd0",
                  name: "GARU-PLS",
                  img: "GARU PLS - Next company.jpg",
                  images:["GARU PLS.1 - Next company.png"],
                  desc:
                    `<ul>
                    <li>
                        Electronic scaled linked Small amount liquid-powder discharging system *custom made.
                    </li>
                    <li>
                        <dl>
                            <dt>Compact size:</dt>
                            <ul>
                                <li>
                                    Users can use this product on a commonly sized desk. The electronic scale has a liquid pump head and
                                    our powder pump is connected.
                                </li>
                            </ul>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>Multi-channel production is possible by special order:</dt>
                            <ul>
                                <li>
                                    One electronic scale (one liquid pump head mounted on the scale) and one powder pump are the basic
                                    configurations, and up to 5 channels can be produced only for special customized order.
                                </li>
                            </ul>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>Pick one of liquid discharge and powder discharge:</dt>
                            <ul>
                                <li>
                                    The selection switch allows you to choose whether to drain liquid or powder.
                                </li>
                            </ul>
                        </dl>
                    </li>
                </ul>
                <dl>
                    <dt>Type 4 models according to liquid flow rate (ml/min)</dt>
                    <dd>1) PLS G100: 0.1g - 100 g (Measure unit: 0.01 gram)</dd>
                    <dd>
                        2) PLS G50: 1g - 50 g (Measure unit: 0.1 gram)
                    </dd>
                    <dd>
                        3) PLS K01: 5g - 1Kg (Measure unit: 1 gram)
                    </dd>
                    <dd>
                        4) PLS K19: 20g - 19Kg (Measure unit: 1 gram)
                    </dd>
                </dl>
                
                <ul>
                    <li>
                        Speed resolution: 1 rpm
                    </li>
                    <li>
                        Speed rate: 0 - 100 rpm
                    </li>
                    <li>
                        Electric scale's screen: LED
                    </li>
                    <li>
                        Mode: Automode or Manual mode
                    </li>
                
                </ul>`
                }
              ]
            },
            {
              id: "603466317edd4007b9f11375e2fc069b",
              product_name: "BOTTLE FILLING PUMP",
              models: [
                {
                  id: "ae21339920ef4c23a944f020ba18432d",
                  name: "NEXT 600-1FC YZ25L",
                  img: "600-1fc yz25l - 1channel - Next company.png",
                  desc:`<ul>
                  <li>Speed: 0.1 - 600 rpm, 0.1 rpm resolution</li>
                  <li>Suitable pump head: YZ25L, KZ25</li>
                  <li>Flow rate: 0 - 6000 ml/min</li>
                  <li>Speed control mode:  Touch screen control</li>
                  <li>Display mode:  True color crystal display english and chinese</li>
                  <li>External control:  RS485 and RS232 (with converter), support MODBUS Protocol mode</li>
                  <li>Dispensing time:  0.1s - 9999 min</li>
                  <li>Suction bag angle:  10˚ - 720˚ (1˚ stepping)</li>
                  <li>Power supply:  AC220V ±10% (Standard) or AC 110V ± 10% (Optional)</li>
                  <li>Operating condition:  Temperature 0 to 40˚C, Relative humidity < 80%, no condensation</li>
                  </ul>`
                },
                {
                  id: "d15add875e26443bbee9380137f30f9c",
                  name: "NEXT 600-1F",
                  img: "600-1F-Next-company.png",
                  desc:`<ul>
                  <li>Drive model: NEXT 600-1F</li>
                  <li>Speed: 0.1 - 600 rpm</li>
                  <li>Suitable pump head: YZ1515x, YZ2515x,KZ25, ZN25</li>
                  <li>Flow range: max 6000ml/min</li>
                  <li>Power supply:  AC220V±10%(standard) or AC110V±10%(optional)</li>
                  <li>Driver dimension:  264x177x153(mm)</li>
                  <li>Display:  4-LED display</li>
                  <li>External control:  Start & stop, direction control, speed control( 0-5V, 0-10V, 4-20mA optional ) RS485, support MODBUS protocol</li>
                  </ul>`
                },
                {
                  id: "4ee3591f17414812861e80ef70be9443",
                  name: "NEXT 600-4F",
                  img: "600-4F-Next-company.png",
                  desc:`<ul>
                  <li>Drive model: NEXT 600-4F</li>
                  <li>Speed: 0.1 - 600 rpm</li>
                  <li>Suitable pump head: YZ35, YZ35-13</li>
                  <li>Flow range: 1.3 - 12000ml/min</li>
                  <li>Power supply:  AC220V±10%(standard) or AC110V±10%(optional)</li>
                  <li>Driver dimension:  345x232x226(mm)</li>
                  <li>Display:  4-LED display</li>
                  <li>External control:  Start & stop, direction control, speed control( 0-5V, 0-10V, 4-20mA optional ) RS485, support MODBUS protocol</li>
                  </ul>`
                }
              ]
            }
          ]
        }
      ]
    }, 
    { // Cate 3
      id: "9c79653415224eaea808fe7cd3812801",
      cate_name: "Filter",
      cate_image: "Filter.png",
      brands: [
        {
          id:"7b43cec548d94d8c85059013da1e0b2a",
          name_brand:"Lepure",
          image_brand:"Lepure logo.webp",
          products: [
            {
              id: "19006c4cd63742d0b76886c3a51d15c3",
              product_name: "Filter cartridge / capsule",
              models: [
                {
                  id: "3428f0da1e13443385db983b80708ab2",
                  name: "LeSiever®Sterilizing Capsule Filter",
                  img: "LeSiever Sterilizing Capsule Filter.jpg",
                  desc: `<ul>
                    <li>
                     This sterilizing capsule filter passed the bacterial challenge test using Brevundimonas diminuta (ATCC 19146) at a minimum challenge concentration of 1x107 CFU/cm2 
                    </li>
                    <li>
                     Under normal filtration conditions, LeSiever sterilizing capsule filter is compatible with common aqueous solutions of pH 1-14, biochemical substances, and most solvents, except for aromatics and chlorides.
                    </li>
                  </ul>`
                },
                {
                  id: "7a6a2e89d13b42fca82b0d5fb7d6e946",
                  name: "Filter cartridge ",
                  img: "Filter Catridge.jpg",
                  desc: ``
                },
              ]
            },
          ]
        }
      ]
    },
    { // Cate 4
      id: "93cb4ffc65174b26a74db0d49b53e4aa",
      cate_name: "Filter Integrity Tester",
      cate_image: "Filter-Integrity-Tester.png",
      brands: [
        {
          id:"2ad06b8a55204fc18265130ea31f3946",
          name_brand:"Neuronbc",
          image_brand: "Neuronbc-logo.jpeg",
          products: [
            {
              id: "4b563804c8e5407097f74197ffe2b4f2",
              product_name: "Integrity Tester",
              models: [
                {
                  id: "4f973d74879e4ca8a082c9611d539121",
                  name: "Filter Integrity Tester V8.0",
                  img: "Neuronbc-Filter integrity test.jpg",
                  desc: `
                  <dl>
                    <dt> The V8.0 integrity tester is a compact and lightweight desktop instrument with a
                    weight of only 11kg.</dt>
                    <dd> 10-inch true-color touch screen design, friendly human-machine interface, simple, fast and reliable operation.</dd>
                    <dd> To meet offline online testing, using pressure sensors with higher accuracy and lower deviation bands to improve the test accuracy of the instrument.</dd>
                    <dd> Automatic self-test function, self-test of multiple performance functions of the instrument itself, after turning on the instrument has instrument self-test function, report faults in time.</dd>
                    <dd> Scientific user management, password login, user classification, electronic signature, etc., in compliance with GMP and FDA CFR 21 part 11.</dd>
                  </dl>`
                },
              ]
            },
          ]
        }
      ]
    },
    { // Cate 5
      id: "3ccc7ec61ff94e60a1ee60faedbbd286",
      cate_name: "Filter Press / Filter Housing / Filter sheet",
      cate_image: "Filter-Press-Filter-Housing-Filter-sheet.png",
      brands: [
        {
          id:"2f2770bd322c4ea893dfd689e750a22b",
          name_brand:"Filtrox",
          image_brand: "Filtrox.jpg",
          products: [
            {
              id: "a5f7d8ae5dc64577bbb0618dd9001555",
              product_name: "Filter press",
              models: [
                {
                  id: "4401de0151aa4ace89e0367256e0b3d9",
                  name: "NOVOX CP",
                  img: "Novox_400_CP_seitlich_geschlossen.jpg",
                  desc: `<ul>
                    <li> completely enclosed filter system for CIP without filter sheets</li>
                    <li> available in following sizes: 400, 600, 800, 1000 and 1200 mm.</li>
                    <li> closing mechanism: pneumatic or electro hydraulic </li>
                    <li> filter plates and cake frames are available in stainless steel 316 / PP</li>
                  </ul>`
                },
              ]
            },
            {
              id: "32c62eb0c2a345418592f77f8bad83d5",
              product_name: "Filter housing",
              models: [
                {
                  id: "e713bb3734e54561b6a787d0cdb2e917",
                  name: "DISCSTAR® G",
                  img: "Discstar_g_geschlossen.jpg",
                  desc: `This filter module housing is designed with screw clamp closure system and standard piping. Ideal for Food & Beverage applications. Designed for heavy duty use and high-capacity depth filtration. Available for 12″ or 16″ filter modules. Full range from 1 to 4 modules.`
                },
              ]
            },
            {
              id: "bc55d580893a45d7b63010d2fea091d1",
              product_name: "Sheet filter",
              models: [
                {
                  id: "f2a0bd47945a41cba8ba9271c82f1f96",
                  name: "Depth filter sheets",
                  img: "filterschichten_ag.jpg",
                  images:[
                    "Carbofil_schichten.jpg",
                    "filterschichten_ag.jpg",
                    "filterschichten_diverse.jpg"
                  ],
                  desc: `<ul>
                    <li> High Purity Depth filter sheets</li>
                    <li> Low levels of extractable endotoxins and ions Compliant to FDA (US Food and Drug Administration) 21 CFR 177.2260</li>
                    <li> ISO 22000 (food safety)</li>
                  </ul>`
                },
              ]
            },
            {
              id: "de63df0dcd6c47159f4aa967741d1338",
              product_name: "Filter module",
              models: [
                {
                  id: "d353069d16e34d44bb8964bf60dc9912",
                  name: "DISCSTAR™ housing",
                  img: "Discstar_g_offen_mit_teller.jpg",
                  desc: `<ul>
                  <li> available with two attachment options for the modules</li>
                  <li> flat gasket (DOE) and double O-ring gasket (DOR).</li>
                  </ul>`
                },
              ]
            },
          ]
        }
      ]
    },
    { // Cate 6
      id: "e9296e194dc04137a0f592c7d2151dc9",
      cate_name: "Glove Integrity Tester",
      cate_image: "Glove-Integrity-Tester.png",
      brands: [
        {
          id:"c7b0ecf4fa3145b7970fde4627e341ba",
          name_brand:"Neuronbc",
          image_brand: "Neuronbc-logo.jpeg",
          products: [
            {
              id: "f17622ca999a4321b0f350c5beaf28b2",
              product_name: "Integrity Tester",
              models: [
                {
                  id: "4c6f549e2b8149508e0dbca9e7df365c",
                  name: "Glove Integrity Tester GT-2.0 Offline",
                  img: "Glove Integrity Tester GT-2.0 Offline.png",
                  desc: `
                  <p>The Off-line Gloves Integrity Tester GT-2.0 produced by Beijing Neuronbc
                  Laboratories is designed according to the positive pressure leak detector principle
                  proposed by GB/T 25915.7-2010/ISO 14644-7:2004 </p>
                  <ul>
                    <li>Working power supply: AC 100-260V 50Hz</li>
                    <li>Rated power: 120W</li>
                    <li>Testing duration: 2-8 min</li>
                    <li>Test accuracy: Minimum aperture 300μm</li>
                    <li>Pressure range: 500-5000Pa</li>
                    <li>Response value: ≤ 50Pa</li>
                    <li>External gas source: Compressed air, nitrogen, inert gas</li>
                    <li>Historical record: 10000 groups</li>
                    <li>Print Function: Built-in Printer</li>
                    <li>Overall dimension: 400*260*250 (height 1)* 270 (height 2) mm</li>
                    <li>Weight: 7.5KG</li>
                    <li>Comply with 21 CFR Part 11: Yes</li>
                  </ul>`
                },
                {
                  id: "ef23f24a159e43b2b4e258e259278534",
                  name: "Glove Integrity Tester GT-2.0 Online",
                  img: "Glove Integrity Tester GT-2.0 Online.png",
                  desc: `
                  <p>
                  The On-line Gloves Integrity Tester GT-2.0 produced by Beijing Neuronbc
                  Laboratories is designed according to the positive pressure leak detector principle
                  proposed by GB/T 25915.7-2010/ISO 14644-7:2004.
                  The simple and easy design won't need any additional equipment, no need to remove the gloves, with fast detection speed and high precision.
                  </p>

                  <ul>
                    <li>Working power supply: AC 100-260V 50Hz</li>
                    <li>Rated power: 120W</li>
                    <li>Testing duration: 2-8min</li>
                    <li>Test accuracy: Minimum aperture 300μm</li>
                    <li>Pressure range: 500-5000Pa</li>
                    <li>Response value: ≤ 50Pa</li>
                    <li>External gas source: Compressed air, nitrogen, inert gas</li>
                    <li>Historical record: 10000 groups</li>
                    <li>Print Function: Built-in Printer</li>
                    <li>Overall dimension: 400*260*250 (height 1)* 270 (height 2) mm</li>
                    <li>Weight: 7.5KG</li>
                    <li>Comply with 21 CFR Part 11: Yes</li>
                  </ul>`
                },
                {
                  id: "420448ac00724f369790f7381742bd2c",
                  name: "Wireless Glove Integrity Tester WGT- 1000 online",
                  img: "Wireless Glove Integrity Tester WGT-1000 online.png",
                  desc: `
                  <p>The on-line glove integrity tester WGT-1000 produced by our company is designed
                  according to the principle of the positive pressure leak detector recommended by GB/T
                  25915.7-2010/ISO 14644-7:2004. It is a special instrument for detecting leakage of
                  gloves.</p>

                  <ul>
                    <li>Working power supply: DC 12V 20W</li>
                    <li>Built-in battery: 12V lithium battery</li>
                    <li>Testing duration: 3-6min</li>
                    <li>Test accuracy: Minimum aperture 300μm</li>
                    <li>Pressure range: 500-3000Pa</li>
                    <li>Response value: ≤ 50Pa</li>
                    <li>Gas source: Built-in air pump</li>
                    <li>Pump: Built-in pump</li>
                    <li>Communication method: WIFI wireless network</li>
                    <li>Historical record: 12000 groups</li>
                    <li>Glove recognition method: RFID/ manual input</li>
                    <li>Print Function: Wireless connects with an external thermal printer</li>
                    <li>Overall dimension: 130*285*180 mm</li>
                    <li>Weight: 7.5KG</li>
                    <li>Comply with 21 CFR Part 11: Yes</li>
                    <li>Audit trail storage: Yes (Data export by USB)</li>
                  </ul>`
                },
                {
                  id: "d7ad3833e621426b80d543a6d52c01a3",
                  name: "Wireless Glove Integrity Tester WGT- 1200 online",
                  img: "Wireless Glove Integrity Tester WGT-1200 online.png",
                  desc: `
                  <p>The on-line glove integrity tester WGT-1200 produced by our company is designed
                  according to the principle of the positive pressure leak detector recommended by GB/T
                  25915.7-2010/ISO 14644-7:2004. It is a special instrument for detecting leakage of
                  gloves. \n
                  Supporting powerful upper computer management software, which can manage
                  multiple detection units at the same time.</p>
                  
                  <ul>
                    <li>Working power supply: DC 12V 20W</li>
                    <li>Built-in battery: 12V lithium battery</li>
                    <li>Testing duration: 3-6min</li>
                    <li>Test accuracy: Minimum aperture 300μm</li>
                    <li>Pressure range: 500-3000Pa</li>
                    <li>Response value: ≤ 50Pa</li>
                    <li>Gas source: Built-in air pump</li>
                    <li>Pump: Built-in pump</li>
                    <li>Communication method: WIFI wireless network</li>
                    <li>Historical record: 12000 groups</li>
                    <li>Glove recognition method: RFID/ manual input</li>
                    <li>Print Function: Customer they can buy an external printer, Can print out A4 format.</li>
                    <li>Overall dimension: 130*285*180 mm</li>
                    <li>Weight: 7.5KG</li>
                    <li>Comply with 21 CFR Part 11: Yes</li>
                    <li>Audit trail storage: Save in the computer.</li>
                    <li>Configure the number of control units: Up to 10 test units can be controlled.</li>
                    <li>Control System: Computer control</li>
                  </ul>`
                },
              ]
            },
          ]
        }
      ]
    },
    { // Cate 7
      id: "9ca727cbfbb84774bfe272d057ffab0e",
      cate_name: "Form Fill Seal System",
      cate_image: "Form-Fill-Seal-System.png",
      brands: [
        {
          id:"604d8eb7392d47f69f59c0d95c819bcc",
          name_brand:"Kiefel Technologies",
          image_brand: "kiefel-technologies Logo.1.jpg",
          products: [
            {
              id: "5dc476bef6264ff4a51832069bfd3147",
              product_name: "Form Fill Seal System",
              models: [
                {
                  id: "a8387c56dff4424fb135c7c450752ee7",
                  name: "Form Fill Seal System",
                  img: "Kiefel-Filling machine.webp",
                  desc: `<ul>
                    <li> The filling excellence is offered in compact small modules, like tabletop fi lling modules, semi-/automatic fi lling & sealing lines, or complete Form-Fill-Seal machines. Solutions to be filled vary from IV solutions (e.g. nutrition), dialysis, anticoagulant and others.</li>
                    <li> A wide range of options allows a customization of filling machines for any bag design. Tubes or ports, single or multi-chamber bags with peel seal, coding & printing,
                    nitrogen flushing, vacuuming, defi ned headspace, laminar flow are some features available upon request.</li>
                  </ul>`
                },
              ]
            },
          ]
        }
      ]
    },
    { // Cate 8 
      id: "723c51f0f6764d56ad233343dcb765a5",
      cate_name: "Surface Disinfection",
      cate_image: "Surface-Disinfection.png",
      brands: [
        {
          id:"33f8e4463b064ada9b9f263e081b7cfe",
          name_brand:"Sanosil",
          image_brand: "logo-sanosil-1.png",
          products: [
            {
              id: "5e587fb0149845379344fde4e2d1acf8",
              product_name: "3D Room Disinfection",
              models: [
                {
                  id: "58040db6e8ef4fe694058c5d94452761",
                  name: "Q-Jet CT10",
                  img: "Qjet-CT10.jpg",
                  desc: `<dl>
                  <dt>Perfect 3D hygiene for rooms from 6 - 100m3</dt>
                   <dd> Aerosol disinfection through automated, airborne system.</dd>
                   <dd> Compressor technology, ideal for vehicles and small room.</dd>
                   <dd> Droplet size: 5-15 μm</dd>
                   <dd> Switchable fog output, 10ml/20ml/min., depending on the size of the room.</dd>
                   <dd> Cost-effective operation with refillable 0.9 litre bottles of active ingredient.</dd>
                   </dl>`
                },
                {
                  id: "cff3027fd206458597330c2b4e272131",
                  name: "Q-Jet CT20",
                  img: "Qjet-CT20.jpg",
                  desc: `<dl>
                    <dt>High-end aerosol disinfector for small to large rooms up to 600m3</dt>
                    <dd> Compressor / fine nozzle technology and micro nozzle connectable turbine, which provides a fine dry disinfection mist.</dd>
                    <dd> Adjustable flow rate: 10-50 ml/min</dd>
                    <dd> Tank size: 5000 ml</dd>
                    <dd> Equipped with 2 different operating modes.</dd>
                    <dd> Fogging cycle: Interval timer, step function, automatic saturation limit</dd>
                    <dd> Operated via a touch screen and also has a logging function.</dd>
                  </dl>`
                },
              ]
            },
            {
              id: "4b87f289c139475fb55563c582d2dd17",
              product_name: "Disinfectant for Aerosol",
              models: [
                {
                  id: "f3fa189d5c784fb8843d9536471716c6",
                  name: "Sanosil S015",
                  img: "Sanosil - S015.jpg",
                  desc: `<dl>
                    <dt>Ready-to- use disinfectant for advanced aerosol applications , AFNOR NFT 72-281 tested.</dt>
                    <dd> Contains: 7.5% hydrogen peroxide, 0.0075% silver</dd>
                    <dd> Effectiveness: Bacterial endospores, Bacteria, Yeasts, Fungi, Viruses (all types)</dd>
                    <dd> Shelf life: 2 Years</dd>
                  </dl>`
                },
              ]
            },
          ]
        }
      ]
    },
    { // Cate 9
      id: "13c2dad74c0445c8a59447e9611879bd",
      cate_name: "Tubing / Gasket",
      cate_image: "Tubing-Gasket.png",
      brands: [
        {
          id:"f73dba1fcb7f4459977049c137f47cae",
          name_brand:"Ami polimer",
          image_brand: "AMI Logo.jpg",
          products: [
            {
              id: "05331cf78b704dc6b230142ee0dd3a58",
              product_name: "Silicone Tubing",
              models: [
                {
                  id: "092adb37f2994f5b8aa078296f784cc0",
                  name: "Silicone Tubing",
                  img: "AMI - Tubing.jpg",
                  desc: ``
                },
              ]
            },
            {
              id: "f650371f270b46d5aa2c9c6e94ee06dc",
              product_name: "Thermo Plastic Tubing (TPE)",
              models: [
                {
                  id: "3f5768c7770a437eb67ca545dab77a1f",
                  name: "Thermo Plastic Tubing (TPE)",
                  img: "AMI - Thermo plastic tubing.jpg",
                  desc: ``
                },
              ]
            },
            {
              id: "ee86bac32d0b46929e9eb2a8a5f51510",
              product_name: "Platinum Cured Silicone Hose ",
              models: [
                {
                  id: "21999ff930794e6794c94b9a93a5c8db",
                  name: "Platinum Cured Silicone Hose ",
                  img: "AMI - Reinforced Hose.jpg",
                  desc: ``
                },
              ]
            }
          ]
        }
      ]
    },
    { // Cate 10
      id: "41863a254b5c47f8b9622bd227268f37",
      cate_name: "Washer Disinfector / Steam Sterilizer",
      cate_image: "Washer-Disinfector-Steam-Sterilizer.png",
      brands: [
        {
          id:"2f8a60d131854b8eb0da01ff6f277737",
          name_brand:"Belimed",
          image_brand: "Belimed_Life_Science_Logo.png",
          products: [
            {
              id: "221d38abb48140b59ae95419e22b230a",
              product_name: "Washer Disinfector",
              models: [
                {
                  id: "764b78b75546485380ae951662e02629",
                  name: "PH 810",
                  img: "Belimed Life Science PH 810 Cleaning System.jpg",
                  desc: `<dl>
                    <dt>The compact one - PH 810</dt>
                    <dd> The GMP-compliant, compact Belimed Life Science Pharma cleaning system was specially developed for the preparation, cleaning and drying of components in contact with the product for the production of drugs or experiments in laboratories.</dd>
                    <dd> One door and two door variant</dd>
                    <dd> Cabin volume: 307 liters</dd>
                    <dd> Loading height: 870 mm</dd>
                    <dd> Outside dimension 1000 x 980 x 2100 mm (W xD xH)</dd>
                    <dd> Max.Load of laundry: 80 kg</dd>
                  </dl>`
                },
                {
                  id: "9e96d949888a4a37aca385785f898fad",
                  name: "PH 8x0.2",
                  img: "Belimed - PH 8x0.2.jpeg",
                  desc: `<dl>
                  <dt>The customizables - PH 8x0.2</dt>
                  <dd> Customer specific GMP cleaning system for component with the product in the
                  manufacture, filling and packaging of pharmaceutical products</dd>
                  <dd> GMP compliant</dd>
                  <dd> 5 different standard sizes</dd>
                  <dd> One and two door variants</dd>
                  <dd> Cabin volume: from 307 to 1500 liters</dd>
                  <dd> Loading height: 800 mm</dd>
                  <dd> High degree of customization options</dd>
                  </dl>`
                },
                {
                  id: "c2bcb60ceb4c4415a605828098a64c58",
                  name: "WD 750IND",
                  img: "WD 750 _ white background.jpg",
                  desc: `<dl>
                    <dt>The large Washer and Desinfector - WD 750IND</dt>
                    <dd> The floor-level loadable WD 750 large-scale cleaning system is the ideal solution for cleaning and drying large non-critical items from pharmaceutical production, the food industry or the cosmetics industry.</dd>
                    <dd> Meets GMP requirements such as complete self-draining of the piping, pump, tank, valves and fittings as well as all surfaces in contact with the product with inclination (chamber roof, chamber floor).</dd>
                    <dd> Four different sizes: From 2200 litres to 5500 litres chamber volume.</dd>
                  </dl>`
                },
              ]
            },
            {
              id: "9666df15fbc848d3a70853bff411b76d",
              product_name: "Rack and accessories",
              models: [
                {
                  id: "e3e3d26b71a940d0b2c7f67a90c19be6",
                  name: "Racks / Washing good carriers",
                  img: "Racks-Washing good carriers.png",
                  desc: `<ul>
                  <li> Customized and trolleys with meet customer need.</li>
                  <li> Can offer customer-specific solutions for production equipment, format components, tableting equipment etc.</li>
                  <li> 3D scanner for the digital recording of wash items. This option can design the wash item carrier optimally for the parts in order to guarantee a reproducible and efficient washing process.</li>
                  </ul>`
                },
              ]
            },
            {
              id: "ea81de0200e84a4ab997a40f29966540",
              product_name: "Steam Sterilizer",
              models: [
                {
                  id: "131a2b94a5854ac0ad60906d91186e7a",
                  name: "LST model",
                  img: "Belimed Life Science - LSTV - Autoklav mit senkrechter Türe und minimalem Footprint.jpg",
                  desc: `<dl>
                    <dt>LST-V-Laboratory sterilizer with a small footprint</dt>
                    <dd> The LST-V steam sterilizers are designed for the sterilization of solid, porous goods as well as for the decontamination of waste in laboratories.</dd>
                    <dd> European Pressure Equipment Directive 97/23/EC and the DIN 58951-2</dd>
                    <dd> Vertically sliding doors is characterized by its compact and ergonomic design.</dd>
                    <dd> Capacity : 305, 440 and 565 liters</dd>
                  </dl>`
                },
                {
                  id: "f3624f3231cb4346b8ba886fac32ef33",
                  name: "BST model",
                  img: "BST_gross_links_solo_render.jpg",
                  desc: `<dl>
                    <dt>The high performance Steam Sterilizer - BST model</dt>
                    <dd> Steam Sterilizer is suitable for a wide range of applications in the pharmaceutical and biotechnological industry and in laboratories.</dd>
                    <dd> Compliant with the following guidelines: cGMP, GAMP5, DIN 58950, DIN 58951, ISPE Baseline Guide, PDA reports</dd>
                    <dd> B-touch software was developed according to GAMP5 and is designed for use in accordance with 21 CFR Part 11.</dd>
                    <dd> Available for both Saturated steam method (BST 6065) and Steam-air mixture method (BST 6060)</dd>
                    <dd> One and two-door versions</dd>
                    <dd> Cabin capacity: from 622 to 4626 litres</dd>
                    <dd> Floor level loading possible</dd>
                    <dd> High degree of individualisation options</dd>
                  </dl>`
                },
              ]
            }
          ]
        }
      ]
    }
  ],

};

export default data;

// Product Category
// 1. Cell Culture media
// 2. Dispensing Powder pump/ Peristaltic pump
// 3. Filter 
// 4. Filter / Filter Integrity tester
// 5. Filter Press / Filter Housing / Filter sheet
// 6. Glove Integrity Tester
// 7. Form Fill Seal System
// 8. Surface Disinfection
// 9. Tubing / Gasket
// 10. Washer Disinfector / Steam Sterilizer


