<template>
  <a-layout-footer :style="footerStyle">
    <div class="container">
      <div class="row g-5">
        <div class="col-lg-4 text-start">
          <h3 class="footer-heading">About Us</h3>
          <p>Name : SPD Biotech Co.,Ltd</p>
          <p>Established : August 2021</p>
          <p>Address :
            1194 Soi Wachirathamsathit 57
            Sukhumvit 101/1
            Bangchak, Phrakanong
            Bangkok 10260
          </p>
          <p>E-mail : sales@spdbiotech.com <br>
            support@spdbiotech.com <br>
            ps@spdbiotech.com
          </p>
          <p>Type of Business : Science and Pharmaceutical equipments / Consumable products
            Consultant services for Pharmaceutical and Biotechnology Solutions</p>
          <router-link to="/about" class="footer-link-more">Learn More</router-link>
        </div>

        <div class="col-6 col-lg-2 text-start">
          <h3 class="footer-heading">Navigation</h3>
          <ul class="footer-links list-unstyled">
            <li><router-link to="/">
                <BIconChevronRight /> Home
              </router-link>
            </li>
            <li><router-link to="/about">
                <BIconChevronRight /> About us
              </router-link></li>
            <li><router-link :to="{
              name: 'product', params: { Id: 'null', cate_id: 'all' }
            }">
                <BIconChevronRight /> Product
              </router-link></li>
            <li><router-link to="/contact">
                <BIconChevronRight /> Contact
              </router-link></li>
          </ul>
        </div>

      </div>
    </div>
  </a-layout-footer>

  <div class="footer-legal">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
          <div class="copyright">
            © Copyright <strong><span>Spdbiotech.com</span></strong> All Rights Reserved
          </div>

          <div class="credits">
            <!-- All the links in the footer should remain intact. -->
            <!-- You can delete the links only if you purchased the pro version. -->
            <!-- Licensing information: https://bootstrapmade.com/license/ -->
            <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/herobiz-bootstrap-business-template/ -->
          </div>

          <!-- <div class="col-md-6">
                        <div class="social-links mb-3 mb-lg-0 text-center text-md-end">
                        <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
                        <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
                        <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
                        <a href="#" class="google-plus"><i class="bi bi-skype"></i></a>
                        <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
                        </div>
                    </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BIconChevronRight } from 'bootstrap-icons-vue'
export default {
  name: 'FooterComponent',
  components: {
    BIconChevronRight
  },
  data() {
    return {
      footerStyle: {
        color: "rgba(255,255,255, 0.7)",
        background: "rgba(255, 255, 255, 0.7)",
      }
    }

  }
}
</script>

<style>
.ant-layout .ant-layout-footer {
  /* font-family: 'EBGaramond'; */
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.footer {
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  font-size: 16px;
  padding: 60px 0;
  text-align: start;
}

.footer-legal {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: aliceblue;
  padding: 40px 0px;
}

.footer-links li {
  line-height: 1.5;
}

.footer-links li a {
  text-decoration: none;
}

.footer-links li a,
a.footer-link-more {
  color: #ffffffb3;
  font-size: 16px;
  font-weight: 400;
}

.footer .footer-links li a:hover,
.footer .footer-links li a:focus {
  color: black;
}

.copyright {
  /* font-family: 'EBGaramond'; */
}

@media (max-width: 400px) {

  ul.footer-links li {
    white-space: nowrap;
  }

}
</style>